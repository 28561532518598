(function () {
    'use strict';

    angular
        .module('portalApp')
        .directive('ycTable', ycTable);

    ycTable.$inject = ['$filter', 'ycTableConstants', 'TimeseriesUtils', 'DataUtils'];

    function ycTable($filter, ycTableConstants, TimeseriesUtils, DataUtils) {
        // Generates table for one or more rows
        // Usage:
        //  <div data-stat-table table-data="vm.tableData"
        //       orig-data="vm.origTableData"
        //       data-show-units-col="true"
        //       data-show-units-row="true"
        //       data-country-name="countryName"
        //       data-switch-to-chart="vm.switchToChart"
        //       data-recalculate-fun="vm.recalculate"
        //       data-tenor-state="vm.tenorState">
        // Input structure:
        //  vm.tableData = {
        //      tableId: "someId",
        //      hideTenors: [Double, Double, ...],
        //      rowIds: [ "1st id", "2nd id", ... ],
        //      rowHeaders: [ "1st header", "2nd header", ... ],
        //      rowTooltips: ["1st tooltip", "2nd tooltip"],
        //      rowUnits: [ "1st unit", "2nd unit", ... ],
        //      rows: [
        //          {
        //              "tenorLabel": {
        //                  value: Double,
        //                  isEditable: Boolean,
        //                  isLastInBlock: Boolean
        //              },
        //              ...
        //          },
        //          ...
        //      ]
        //  }
        //
        //  vm.tenorState = {
        //      "tenorLabel": {
        //          recalculated: Boolean,
        //          calculating: Boolean,
        //          failed: Boolean
        //      },
        //      ...
        //  }
        var directive = {
            restrict: 'A',
            templateUrl: 'app/components/yc-table/yc-table.html',
            scope: {
                tableData: '=',
                origData: '<',
                showUnitsCol: '<',
                showUnitsRow: '<',
                countryName: '<',
                switchToChart: '=',
                recalculateFun: '=',
                tenorState: '='
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            // assign ID to the main directive element
            element.attr('id', scope.tableData.tableId);

            // define variables and functions in the scope of this directive
            scope.activeTenors = ycTableConstants.tenorList.labels;
            scope.someTenorsHidden = false;
            scope.descHidden = true;
            scope.hiddenTenorsDefined = (angular.isDefined(scope.tableData.hideTenors)
                && angular.isArray(scope.tableData.hideTenors)
                && scope.tableData.hideTenors.length > 0);
            scope.noRecalculate = (scope.recalculateFun == null);
            scope.exportToCsv = exportToCsv;
            scope.resetTable = resetTable;
            scope.recalculate = recalculate;
            scope.toggleTenors = toggleTenors;
            scope.toggleDesc = toggleDesc;
            scope.getModifiedTenors = getModifiedTenors;
            scope.getNotRecalculatedTenors = getNotRecalculatedTenors;
            scope.onYcInputChanged = onYcInputChanged;

            // hide some tenor columns if requested so
            scope.toggleTenors();

            // recalculate the curves based on user-supplied Sharpe Ratios
            function recalculate() {
                if (scope.noRecalculate) {
                    return;
                }
                const zcRowIx = scope.tableData.rowIds.findIndex(function (rid) {
                    return rid === 'sharpe_ratio_zc';
                });
                if (zcRowIx > -1) {
                    const tenors = scope.getNotRecalculatedTenors();
                    const values = [];
                    for (var i = 0; i < tenors.length; i++) {
                        const thisTenor = tenors[i];
                        values.push(scope.tableData.rows[zcRowIx][thisTenor].value);
                    }
                    scope.recalculateFun(tenors, values);
                }
            }

            function onYcInputChanged(rowIx, colIx) {
                const thisTenorLabel = scope.activeTenors[colIx];
                scope.tenorState[thisTenorLabel].recalculated = false;
                scope.tenorState[thisTenorLabel].failed = false;
            }

            // returns the list of tenors where user has modified a zero-coupon SR
            function getModifiedTenors() {
                try {
                    const zcRowIx = scope.tableData.rowIds.findIndex(function (rid) {
                        return rid === 'sharpe_ratio_zc';
                    });
                    // unlikely case, but let's keep it safe
                    if (zcRowIx === -1) {
                        return [];
                    }
                    return ycTableConstants.tenorList.labels.filter(function (lab) {
                        const thisTabValue = scope.tableData.rows[zcRowIx][lab].value;
                        const origTabValue = scope.origData.rows[zcRowIx][lab].value;
                        return thisTabValue !== origTabValue;
                    });
                } catch (e) {
                    return [];
                }

            }

            // returns the list of tenors where user has modified a zero-coupon SR
            // and for which he did not run the re-calculation yet
            function getNotRecalculatedTenors() {
                try {
                    return ycTableConstants.tenorList.labels.filter(function (lab) {
                        return !scope.tenorState[lab].recalculated;
                    });
                } catch (e) {
                    return [];
                }
            }

            // auxiliary function saving the content to CSV file
            function exportToCsv() {
                var fileName = scope.countryName + " -- YCM Report Table (exported on "
                    + $filter('date')(new Date(), 'dd-MMM-yyyy') + ").csv";
                var tableCsvContent = ['Description', 'Unit']
                    .concat(ycTableConstants.tenorList.labels)
                    .join(',');
                tableCsvContent += '\n';
                for (var rx = 0; rx < scope.tableData.rows.length; rx++) {
                    var thisRow = [];
                    // add "description" cell
                    if (angular.isArray(scope.tableData.rowHeaders)
                        && angular.isDefined(scope.tableData.rowHeaders[rx])) {
                        thisRow.push(scope.tableData.rowHeaders[rx]);
                    } else {
                        thisRow.push('');
                    }
                    // add "units" cell
                    if (angular.isArray(scope.tableData.rowUnits)
                        && angular.isDefined(scope.tableData.rowUnits[rx])) {
                        thisRow.push('"' + scope.tableData.rowUnits[rx].toString() + '"');
                    } else {
                        thisRow.push('');
                    }
                    // add "value" cells for all tenors
                    for (var lx = 0; lx < ycTableConstants.tenorList.labels.length; lx++) {
                        var val = scope.tableData.rows[rx][ycTableConstants.tenorList.labels[lx]].value;
                        if (val !== '') {
                            val = Math.round(val * 1000) / 1000;
                        }
                        thisRow.push(val);
                    }
                    // add new row to the table variable
                    tableCsvContent += thisRow.join(',');
                    tableCsvContent += '\n';
                }

                // save file
                DataUtils.saveFile('text/csv', tableCsvContent, fileName);
            }

            // auxiliary function resetting table to its original state
            function resetTable(){
                scope.tableData = angular.copy(scope.origData);
                scope.tenorState = ycTableConstants.tenorList.labels.reduce(function (accum, tLab) {
                    accum[tLab] = {
                        recalculated: true,
                        calculating: false,
                        failed: false
                    };
                    return accum;
                }, {});
            }

            // auxiliary function toggling the hidden tenor columns
            function toggleTenors() {
                scope.someTenorsHidden = !scope.someTenorsHidden;
                if (scope.someTenorsHidden && scope.hiddenTenorsDefined) {
                    scope.activeTenors = [];
                    for (var lx = 0; lx < ycTableConstants.tenorList.values.length; lx++) {
                        const thisTenor = ycTableConstants.tenorList.values[lx];
                        var thisIx = scope.tableData.hideTenors.findIndex(function (el) {
                            return el === thisTenor;
                        });
                        if (thisIx === -1) {
                            scope.activeTenors.push(ycTableConstants.tenorList.labels[lx]);
                        }
                    }
                } else {
                    scope.activeTenors = ycTableConstants.tenorList.labels;
                }
            }

            // auxiliary function toggling the descriptions of rows
            function toggleDesc() {
                scope.descHidden = !scope.descHidden;
            }
        }
    }

})();
