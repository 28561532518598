(function () {
    'use strict';

    angular
        .module('portalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('main', {
                parent: 'home',
                url: 'overview/',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'main.pageTitle.overview'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/main/main.html',
                        controller: 'MainController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('main');
                        return $translate.refresh();
                    }],
                    // list of countries the current user is subscribed for
                    subscribedCountryList: ['SubscribedCountries', function (SubscribedCountries) {
                        return SubscribedCountries.query({}).$promise;
                    }],
                    // latest YCM rounds of all subscribed countries
                    latestYcmRoundList: ['$q', '$stateParams', 'Round', 'subscribedCountryList', function ($q, $stateParams, Round, subscribedCountryList) {
                        var promises = [];
                        for (var ix = 0; ix < subscribedCountryList.length; ix++) {
                            const thisIx = ix;
                            promises.push(Round.get({
                                "countryId": subscribedCountryList[ix].id,
                                "productCode": "YCM",
                                "applicationName": "ycm"
                            }).$promise
                                .catch(function () {
                                    return $q.when({
                                        countryId: subscribedCountryList[thisIx].id,
                                        countryName: subscribedCountryList[thisIx].name,
                                        created: null
                                    });
                                }));
                        }
                        return $q.all(promises);
                    }],
                    // resources for all the latest YCM rounds of all subscribed countries
                    latestYcmResourcesList: ['$q', '$stateParams', 'Resource', 'latestYcmRoundList', function ($q, $stateParams, Resource, latestYcmRoundList) {
                        var promises = [];
                        for (var ix = 0; ix < latestYcmRoundList.length; ix++) {
                            if (!latestYcmRoundList[ix].id) {
                                promises.push($q.when([]));
                                continue;
                            }
                            promises.push(Resource.query({
                                roundId: latestYcmRoundList[ix].id,
                                productCode: "YCM"
                            }).$promise
                                .catch(function () {
                                    return $q.when([]);
                                }));
                        }
                        return $q.all(promises);
                    }]
                }
            })
            .state('selectedCountry', {
                parent: 'main',
                url: 'country-page/{countryId}',
                data: {
                    pageTitle: 'main.pageTitle.countryPage'
                },
                params: {
                    countryId: null,
                    countryName: null,
                    mainView: 'actual',
                    chartType: null
                },
                views: {
                    'actual': {
                        templateUrl: 'app/actual/actual.html',
                        controller: 'ActualController',
                        controllerAs: 'vm',
                        resolve: {
                            latestYcmResources: ['$q', 'Resource', 'latestYcmRound', function ($q, Resource, latestYcmRound) {
                                if (latestYcmRound === null) {
                                    return $q.when([]);
                                }
                                return Resource
                                    .query({
                                        roundId: latestYcmRound.id,
                                        productCode: "YCM"
                                    })
                                    .$promise;
                            }],
                            latestYcmResults: ['$q', 'YieldCurve', 'latestYcmRound', function ($q, YieldCurve, latestYcmRound) {
                                if (latestYcmRound === null) {
                                    return $q.when([]);
                                }
                                return YieldCurve
                                    .query({
                                        roundId: latestYcmRound.id,
                                        actionCode: 'yo'
                                    })
                                    .$promise
                                    .catch(function () {
                                        return $q.when([]);
                                    });
                            }]
                        }
                    },
                    'yc-chart': {
                        templateUrl: 'app/yc-chart/yc-chart.html',
                        controller: 'YcChartController',
                        controllerAs: 'vm'
                    },
                    'yc-chart-maturity@selectedCountry': {
                        templateUrl: 'app/yc-chart/yc-chart-maturity.html',
                        controller: 'YcChartMaturityController',
                        controllerAs: 'vm'
                    },
                    'yc-chart-time@selectedCountry': {
                        templateUrl: 'app/yc-chart/yc-chart-time.html',
                        controller: 'YcChartTimeController',
                        controllerAs: 'vm',
                        resolve: {
                            ycmCurveSeries: ['$q', 'allYcmRounds', 'YieldCurve', function ($q, allYcmRounds, YieldCurve) {
                                const curves = [
                                    'gov_zero_curve',
                                    'irs_quarterly',
                                    'ccy_continuous',
                                    'sharpe_ratio_irs',
                                    'sharpe_ratio_irs_breakeven_std'
                                ];
                                // get the list of this country YCM round IDs
                                const roundIdList = allYcmRounds.map(function (ycmr) {
                                    return ycmr.id;
                                });
                                var promises = [];
                                for (var ix = 0; ix < curves.length; ix++) {
                                    const thisCurve = curves[ix];
                                    promises.push(
                                        YieldCurve.query({
                                            roundIdList: roundIdList,
                                            curveName: thisCurve,
                                            actionCode: 'yo',
                                            maxTenor: 3650
                                        }).$promise
                                            .then(function (response) {
                                                return $q.when({name: thisCurve, data: response});
                                            })
                                            .catch(function () {
                                                return $q.when({name: thisCurve, data: []});
                                            }));
                                }
                                return $q.all(promises);
                            }],
                            ycmBrekevenSRSeries: ['$q', 'latestYcmRound', 'TimeSeriesData', function ($q, latestYcmRound, TimeSeriesData) {
                                var tenorList = ['3M', '6M', '1Y', '2Y', '3Y', '4Y', '5Y'];
                                var promises = [];
                                for (var ix = 0; ix < tenorList.length; ix++) {
                                    const thisTenor = tenorList[ix];
                                    promises.push(
                                        TimeSeriesData.query({
                                            roundId: latestYcmRound.id,
                                            actionCode: 'yo',
                                            applicationVariable: 'sharpe_ratio_irs_breakeven_' + thisTenor,
                                            applicationId: 6,
                                            noDateLimit: true
                                        }).$promise
                                            .then(function (response) {
                                                return $q.when({name: thisTenor, data: response});
                                            })
                                            .catch(function () {
                                                return $q.when({name: thisTenor, data: []});
                                            }));
                                }
                                return $q.all(promises);
                            }]
                        }
                    },
                    'fpas': {
                        templateUrl: 'app/fpas/fpas.html',
                        controller: 'FpasController',
                        controllerAs: 'vm',
                        resolve: {
                            latestFullFpasResources: ['$q', 'Resource', 'latestFullFpasRound', function ($q, Resource, latestFullFpasRound) {
                                if (latestFullFpasRound === null) {
                                    return $q.when([]);
                                }
                                return Resource
                                    .query({
                                        roundId: latestFullFpasRound.id,
                                        productCode: "FPAS"
                                    })
                                    .$promise;
                            }],
                            latestFpasResources: ['$q', 'Resource', 'latestFpasRound', function ($q, Resource, latestFpasRound) {
                                if (latestFpasRound === null) {
                                    return $q.when([]);
                                }
                                return Resource
                                    .query({
                                        roundId: latestFpasRound.id,
                                        productCode: "FPAS",
                                        addCpiCsv: true
                                    })
                                    .$promise;
                            }],
                            allFpasResources: ['$q', 'Resource', 'allFpasRounds', function ($q, Resource, allFpasRounds) {
                                if (allFpasRounds === null || allFpasRounds.length === 0) {
                                    return $q.when([]);
                                }
                                var promises = [];
                                for (var ix = 0; ix < allFpasRounds.length; ix++) {
                                    promises.push(Resource
                                        .query({
                                            roundId: allFpasRounds[ix].id,
                                            productCode: "FPAS",
                                            addCpiCsv: true
                                        })
                                        .$promise);
                                }
                                return $q.all(promises);
                            }]
                        }
                    },
                    'archive': {
                        templateUrl: 'app/archive/archive.html',
                        controller: 'ArchiveController',
                        controllerAs: 'vm',
                        resolve: {
                            archiveResources: ['$q', 'Resource', 'allYcmRounds', function ($q, Resource, allYcmRounds) {
                                if (allYcmRounds === null || allYcmRounds.length === 0) {
                                    return $q.when([]);
                                }
                                var promises = [];
                                for (var ix = 0; ix < allYcmRounds.length; ix++) {
                                    promises.push(Resource
                                        .query({
                                            roundId: allYcmRounds[ix].id,
                                            productCode: "YCM"
                                        })
                                        .$promise);
                                }
                                return $q.all(promises);
                            }]
                        }
                    },
                    'country-note': {
                        templateUrl: 'app/country-note/country-note.html',
                        controller: 'CountryNoteController',
                        controllerAs: 'vm',
                        resolve: {
                            countryNotes: ['$q', '$stateParams', 'CountryNote', function ($q, $stateParams, CountryNote) {
                                return CountryNote.query({
                                    "countryId": $stateParams.countryId
                                }).$promise
                                    .catch(function () {
                                        return $q.when([]);
                                    });
                            }]
                        }
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', 'subscribedCountryList', '$stateParams', '$state',
                        function ($translate, $translatePartialLoader, subscribedCountryList, $stateParams, $state) {
                            $translatePartialLoader.addPart('country');
                            var countryObj = subscribedCountryList.find(function (c) {
                                return (c.id == $stateParams.countryId);
                            });
                            $stateParams.countryName = countryObj.name;
                            return $translate.refresh();
                        }],
                    latestYcmRound: ['$q', '$stateParams', 'Round', function ($q, $stateParams, Round) {
                        return Round.get({
                            "countryId": $stateParams.countryId,
                            "productCode": "YCM",
                            "applicationName": "ycm"
                        }).$promise
                            .catch(function () {
                                return $q.when({});
                            });
                    }],
                    allYcmRounds: ['$q', '$stateParams', 'Round', function ($q, $stateParams, Round) {
                        return Round.query({
                            "countryId": $stateParams.countryId,
                            "productCode": "YCM"
                        }).$promise
                            .catch(function () {
                                return $q.when([]);
                            });
                    }],
                    allFpasRounds: ['$q', '$stateParams', 'Round', function ($q, $stateParams, Round) {
                        return Round.query({
                            "countryId": $stateParams.countryId,
                            "productCode": "FPAS"
                        }).$promise
                            .catch(function () {
                                return $q.when([]);
                            });
                    }],
                    latestFullFpasRound: ['$q', '$stateParams', 'Round', function ($q, $stateParams, Round) {
                        return Round.get({
                            "countryId": $stateParams.countryId,
                            "productCode": "FPAS",
                            "applicationName": "fpas",
                            "typeFilter": "regular"
                        }).$promise
                            .catch(function () {
                                return $q.when({});
                            });
                    }],
                    latestFpasRound: ['$q', '$stateParams', 'Round', function ($q, $stateParams, Round) {
                        return Round.get({
                            "countryId": $stateParams.countryId,
                            "productCode": "FPAS",
                            "applicationName": "fpas"
                        }).$promise
                            .catch(function () {
                                return $q.when({});
                            });
                    }]
                }
            });
    }
})();
