(function () {
    'use strict';
    angular
        .module('portalApp')
        .factory('SummaryFile', SummaryFile);

    SummaryFile.$inject = ['$resource'];

    function SummaryFile($resource) {
        var resourceUrl = 'api/resources/summary-file';

        return $resource(resourceUrl, {}, {
            'download': {
                method: 'GET',
                responseType: "arraybuffer",
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
