(function () {
    'use strict';
    angular
        .module('portalApp')
        .controller('YcChartMaturityController', YcChartMaturityController);

    YcChartMaturityController.$inject = ['$state', 'DateUtils', 'YieldCurve', 'subscribedCountryList', 'allYcmRounds'];

    function YcChartMaturityController($state, DateUtils, YieldCurve, subscribedCountryList, allYcmRounds) {
        var vm = this;
        vm.mainView = $state.params.mainView;
        vm.chartType = $state.params.chartType || 'maturity';
        vm.country = subscribedCountryList.find(function (c) {
            return (c.id == $state.params.countryId);
        });
        vm.allYcmRounds = allYcmRounds;
        vm.langKey = 'en';
        //convert available dates to Moment objects
        vm.availableDates = allYcmRounds.map(function (ycmr) {
            return moment(ycmr.created).set('hour', 12);
        });
        //sort available dates ascending and get the latest available date if any
        vm.lastAvailable = null;
        if (vm.availableDates && vm.availableDates.length > 0) {
            vm.availableDates.sort(function (a, b) {
                return a.utc() - b.utc();
            });
            vm.lastAvailable = vm.availableDates[vm.availableDates.length - 1];
        }
        vm.selectedDates = [];
        //switch to the month of the latest available date
        vm.activeMonth = vm.lastAvailable;
        //define default data set for the chart
        vm.chartData = {
            chartId: "yc-chart-" + vm.country.code,
            lineSeries: {irs: [], ccy: [], zero: [], irsSr: []},
            lineLegends: []
        };
        vm.curveType = "irs";
        vm.chartTitle = "IRS Yield Curve";
        vm.yAxisTitle = "Yield (% pa, quarterly simple)";
        vm.chartMinTenor = 0;
        vm.chartMaxTenor = 3653;
        vm.minSlider = 2;
        vm.maxSlider = 10;
        vm.sliderStep = 2;
        vm.curveRangeLimit = 10;
        vm.curveRangeModel = 10;
        //list all the methods
        vm.chartDataFromYcmResults = chartDataFromYcmResults;
        vm.clearDates = clearDates;
        vm.selectCurrent = selectCurrent;
        vm.onCurveRangeChanged = onCurveRangeChanged;
        vm.onCurveRangeSaved = onCurveRangeSaved;
        vm.onDaySelected = onDaySelected;
        vm.irsCurveSelected = irsCurveSelected;
        vm.ccyCurveSelected = ccyCurveSelected;
        vm.zeroCurveSelected = zeroCurveSelected;
        vm.irsSharpeRatioSelected = irsSharpeRatioSelected;
        vm.selectCurrent();

        //***************************************************************
        function irsCurveSelected() {
            if (vm.curveType === "ccy" && vm.curveRangeLimit === 5) {
                vm.chartMaxTenor = 3653;
                vm.curveRangeLimit = 10;
                vm.curveRangeModel = 10;
            }
            vm.curveType = "irs";
            vm.chartTitle = "IRS Yield Curve";
            vm.maxSlider = 10;
            vm.sliderStep = 2;
            vm.yAxisTitle = "Yield (% pa, quarterly simple)";
        }

        function ccyCurveSelected() {
            vm.curveType = "ccy";
            vm.chartTitle = "Cross Currency Float-to-Fix Yield Curve";
            vm.maxSlider = 5;
            vm.sliderStep = 1;
            vm.chartMaxTenor = Math.min(1827, vm.chartMaxTenor);
            vm.curveRangeLimit = Math.min(5, vm.curveRangeLimit);
            vm.curveRangeModel = Math.min(5, vm.curveRangeModel);
            vm.yAxisTitle = "Yield (% pa, continuous)";
        }

        function zeroCurveSelected() {
            if (vm.curveType === "ccy" && vm.curveRangeLimit === 5) {
                vm.chartMaxTenor = 3653;
                vm.curveRangeLimit = 10;
                vm.curveRangeModel = 10;
            }
            vm.curveType = "zero";
            vm.chartTitle = "Government Zero Curve";
            vm.maxSlider = 10;
            vm.sliderStep = 2;
            vm.yAxisTitle = "Yield (% pa, continuous)";
        }

        function irsSharpeRatioSelected() {
            if (vm.curveType === "ccy" && vm.curveRangeLimit === 5) {
                vm.chartMaxTenor = 3653;
                vm.curveRangeLimit = 10;
                vm.curveRangeModel = 10;
            }
            vm.curveType = "irsSr";
            vm.chartTitle = "Sharpe Ratios IRS";
            vm.maxSlider = 10;
            vm.sliderStep = 2;
            vm.yAxisTitle = "Fraction";
        }

        function onCurveRangeChanged() {
            vm.curveRangeLimit = Math.max(2, vm.curveRangeModel);
        }

        function onCurveRangeSaved() {
            vm.chartMaxTenor = vm.curveRangeLimit * 365 + Math.ceil(vm.curveRangeLimit / 4);
        }

        function onDaySelected(event, dateObj) {
            var unselected = dateObj.mdp.selected;
            var dateString = DateUtils.convertLocalDateToServer(dateObj.date.toDate());
            if (unselected) {
                var curveIndex = vm.chartData.lineLegends
                    .findIndex(function (elem) {
                        return elem === dateString;
                    });
                if (curveIndex > -1) {
                    //remove line from the chart
                    vm.chartData.lineSeries.irs.splice(curveIndex, 1);
                    vm.chartData.lineSeries.ccy.splice(curveIndex, 1);
                    vm.chartData.lineSeries.zero.splice(curveIndex, 1);
                    vm.chartData.lineSeries.irsSr.splice(curveIndex, 1);
                    //remove line from the chart legend
                    vm.chartData.lineLegends.splice(curveIndex, 1);
                }
            } else {
                var resIndex = vm.allYcmRounds.findIndex(function (ycmr) {
                    return dateString == ycmr.created;
                });
                if (resIndex > -1) {
                    //load curves data
                    YieldCurve
                        .query({
                            roundId: vm.allYcmRounds[resIndex].id,
                            actionCode: 'yo'
                        })
                        .$promise
                        .then(chartDataFromYcmResults)
                        .catch(function () {
                            return [];
                        });
                }
            }
        }

        function clearDates() {
            vm.chartData = {
                chartId: "yc_chart",
                lineSeries: {irs: [], ccy: [], zero: [], irsSr: []},
                lineLegends: []
            };
            vm.selectedDates = [];
        }

        /**
         * Adds latest available curve to the chart (if any)
         */
        function selectCurrent() {
            if (vm.lastAvailable && vm.selectedDates.findIndex(function (dt) {
                return dt === vm.lastAvailable;
            }) === -1) {
                vm.selectedDates.push(vm.lastAvailable);
                vm.activeMonth = vm.lastAvailable;
                onDaySelected([], {date: vm.lastAvailable, mdp: {selected: false}});
            }
        }

        /**
         * Converts YCM results to the input structure for yc-chart directive
         * @param ycmResults -- resulting array of curves
         */
        function chartDataFromYcmResults(ycmResults) {
            const curves = {
                zero: 'gov_zero_curve',
                irs: 'irs_quarterly',
                ccy: 'ccy_continuous',
                irsSr: 'sharpe_ratio_irs'
            };
            const curveNames = Object.keys(curves);
            var dateString = 'undefined';
            for (var i = 0; i < curveNames.length; i++) {
                const cName = curveNames[i];
                const res = ycmResults.find(function (el) {
                    return el.name === curves[cName];
                });
                if (angular.isUndefined(res) || angular.isUndefined(res.yieldCurveData)) {
                    vm.chartData.lineSeries[cName].push([]);
                } else {
                    vm.chartData.lineSeries[cName].push(res.yieldCurveData.map(reduceYcData));
                    dateString = res.date;
                }
            }
            vm.chartData.lineLegends.push(dateString);
        }

        /**
         * Slims down the object to contain only those fields needed for chart
         * @param ycData -- array of yield curve data points
         * @returns {{tenorDays: *, tenorLabel: *, value: *}}
         */
        function reduceYcData(ycData) {
            return {
                tenorDays: ycData.tenorDays,
                tenorLabel: ycData.tenorLabel,
                value: ycData.value
            };
        }
    }
})();
