(function () {
    'use strict';

    angular
        .module('portalApp')
        .constant('ycTableConstants', {
            'tenorList': {
                values: [1, 7, Math.floor(365 / 12), Math.floor(365 / 6), Math.floor(365 / 4),
                    Math.floor(365 / 2), 365, 365 * 2, 365 * 3, 365 * 4, 365 * 5, 365 * 6, 365 * 7, 365 * 8,
                    365 * 9, 365 * 10],
                labels: ['1D', '1W', '1M', '2M', '3M', '6M', '1Y', '2Y', '3Y', '4Y', '5Y', '6Y',
                    '7Y', '8Y', '9Y', '10Y']
            }
        });
})();
