(function () {
    'use strict';
    angular
        .module('portalApp')
        .controller('YcChartTimeController', YcChartTimeController);

    YcChartTimeController.$inject = ['$state', 'DateUtils', 'YieldCurve', 'subscribedCountryList', 'ycmCurveSeries',
        'ycmBrekevenSRSeries'];

    function YcChartTimeController($state, DateUtils, YieldCurve, subscribedCountryList, ycmCurveSeries,
                                   ycmBrekevenSRSeries) {
        var vm = this;
        vm.ignoreTenors = ['4M'];
        vm.mainView = $state.params.mainView;
        vm.chartType = $state.params.chartType || 'maturity';
        vm.country = subscribedCountryList.find(function (c) {
            return (c.id == $state.params.countryId);
        });
        vm.allCurvesData = chartDataFromYcmSeries();
        vm.selectedTenors = [];
        vm.availableTenors = [];
        vm.selectedSR = [];
        vm.chartData = {
            chartId: "yc-ts-chart-" + vm.country.code,
            lineSeries: [],
            lineLegends: [],
            lineBands: []
        };
        vm.lineWidth = [];
        vm.irsCurveSelected = irsCurveSelected;
        vm.ccyCurveSelected = ccyCurveSelected;
        vm.zeroCurveSelected = zeroCurveSelected;
        vm.irsSharpeRatioSelected = irsSharpeRatioSelected;
        vm.onTenorPicked = onTenorPicked;
        vm.onTenorUnpicked = onTenorUnpicked;
        vm.onSharpeRatioPicked = onSharpeRatioPicked;
        vm.onSharpeRatioUnpicked = onSharpeRatioUnpicked;

        // by default select 1Y & 5Y tenors of IRS curve and only actual SR
        vm.defaultTenorPicks = ['1Y', '5Y'];
        vm.defaultSRPicks = ['actual'];
        irsCurveSelected();

        //***************************************************************
        function irsCurveSelected() {
            vm.curveType = "irs";
            vm.chartTitle = "IRS Yield Curve";
            vm.yAxisTitle = "Yield (% pa, quarterly simple)";
            vm.availableTenors = Object.keys(vm.allCurvesData.irs);
            vm.selectedTenors = [];
            vm.chartData.lineSeries = [];
            vm.chartData.lineLegends = [];
            vm.chartData.lineBands = [];
            vm.lineWidth = [];
            for (var i = 0; i < vm.defaultTenorPicks.length; i++) {
                onTenorPicked(vm.defaultTenorPicks[i]);
            }
        }

        function ccyCurveSelected() {
            vm.curveType = "ccy";
            vm.chartTitle = "Cross Currency Float-to-Fix Yield Curve";
            vm.yAxisTitle = "Yield (% pa, continuous)";
            vm.availableTenors = Object.keys(vm.allCurvesData.ccy);
            vm.selectedTenors = [];
            vm.chartData.lineSeries = [];
            vm.chartData.lineLegends = [];
            vm.chartData.lineBands = [];
            vm.lineWidth = [];
            for (var i = 0; i < vm.defaultTenorPicks.length; i++) {
                onTenorPicked(vm.defaultTenorPicks[i]);
            }
        }

        function zeroCurveSelected() {
            vm.curveType = "zero";
            vm.chartTitle = "Government Zero Curve";
            vm.yAxisTitle = "Yield (% pa, continuous)";
            vm.availableTenors = Object.keys(vm.allCurvesData.zero);
            vm.selectedTenors = [];
            vm.chartData.lineSeries = [];
            vm.chartData.lineLegends = [];
            vm.chartData.lineBands = [];
            vm.lineWidth = [];
            for (var i = 0; i < vm.defaultTenorPicks.length; i++) {
                onTenorPicked(vm.defaultTenorPicks[i]);
            }
        }

        function irsSharpeRatioSelected() {
            vm.curveType = "irsSr";
            vm.chartTitle = "Sharpe Ratios IRS";
            vm.yAxisTitle = "Fraction";
            vm.availableTenors = Object.keys(vm.allCurvesData.irsSr);
            vm.selectedTenors = [];
            vm.chartData.lineSeries = [];
            vm.chartData.lineLegends = [];
            vm.chartData.lineBands = [];
            vm.lineWidth = [];
            vm.selectedSR = angular.copy(vm.defaultSRPicks);
            for (var i = 0; i < vm.defaultTenorPicks.length; i++) {
                onTenorPicked(vm.defaultTenorPicks[i]);
            }
        }

        function onTenorPicked(tenorLabel) {
            if (vm.selectedTenors.includes(tenorLabel)) {
                onTenorUnpicked(tenorLabel);
            } else {
                vm.selectedTenors.push(tenorLabel);
                if (vm.curveType === 'irsSr') {
                    if (vm.selectedSR.includes('actual') && angular.isDefined(vm.allCurvesData.irsSr[tenorLabel])) {
                        vm.chartData.lineSeries.push(vm.allCurvesData.irsSr[tenorLabel]);
                        vm.chartData.lineLegends.push(tenorLabel + ' (actual)');
                        vm.chartData.lineBands.push(0);
                        vm.lineWidth.push(3);
                    }
                    if (vm.selectedSR.includes('break-even') && angular.isDefined(vm.allCurvesData.irsSr[tenorLabel])) {
                        vm.chartData.lineSeries.push(vm.allCurvesData.irsSrBreakeven[tenorLabel]);
                        vm.chartData.lineLegends.push(tenorLabel + ' (break-even)');
                        vm.chartData.lineBands.push(vm.allCurvesData.irsSrBreakevenStd[tenorLabel]);
                        vm.lineWidth.push(1.5);
                    }
                } else {
                    vm.chartData.lineSeries.push(vm.allCurvesData[vm.curveType][tenorLabel]);
                    vm.chartData.lineLegends.push(tenorLabel);
                    vm.chartData.lineBands.push(0);
                    vm.lineWidth.push(3);
                }
            }
        }

        function onTenorUnpicked(tenorLabel) {
            const tenorIx = vm.selectedTenors.findIndex(function (lb) {
                return lb === tenorLabel;
            });
            if (tenorIx > -1) {
                vm.selectedTenors.splice(tenorIx, 1);
            }
            var tenorLabelList = (vm.curveType === 'irsSr')
                ? [tenorLabel + ' (actual)', tenorLabel + ' (break-even)']
                : [tenorLabel];
            for (var i = 0; i < tenorLabelList.length; i++) {
                const thisTenorLabel = tenorLabelList[i];
                const curveIndex = vm.chartData.lineLegends
                    .findIndex(function (elem) {
                        return elem === thisTenorLabel;
                    });
                if (curveIndex > -1) {
                    //remove line from the chart
                    vm.chartData.lineSeries.splice(curveIndex, 1);
                    //remove line from the chart legend
                    vm.chartData.lineLegends.splice(curveIndex, 1);
                    // drop confidence bands width
                    vm.chartData.lineBands.splice(curveIndex, 1);
                    // drop line width
                    vm.lineWidth.splice(curveIndex, 1);
                }
            }
        }

        function onSharpeRatioPicked(sharpeRatioLabel) {
            var thisCurveType = (sharpeRatioLabel === 'actual')
                ? 'irsSr'
                : 'irsSrBreakeven';
            if (vm.selectedSR.includes(sharpeRatioLabel)) {
                onSharpeRatioUnpicked(sharpeRatioLabel);
            } else {
                vm.selectedSR.push(sharpeRatioLabel);
                for (var i = 0; i < vm.selectedTenors.length; i++) {
                    const thisTenorLabel = vm.selectedTenors[i];
                    if (angular.isDefined(vm.allCurvesData[thisCurveType][thisTenorLabel])) {
                        vm.chartData.lineSeries.push(vm.allCurvesData[thisCurveType][thisTenorLabel]);
                        vm.chartData.lineLegends.push(thisTenorLabel + ' (' + sharpeRatioLabel + ')');
                        vm.chartData.lineBands.push((sharpeRatioLabel === 'actual') ? 0 : vm.allCurvesData.irsSrBreakevenStd[thisTenorLabel]);
                        vm.lineWidth.push((sharpeRatioLabel === 'actual') ? 3 : 1.5);
                    }
                }
            }
        }

        function onSharpeRatioUnpicked(sharpeRatioLabel) {
            const srIx = vm.selectedSR.findIndex(function (lb) {
                return lb === sharpeRatioLabel;
            });
            if (srIx > -1) {
                vm.selectedSR.splice(srIx, 1);
            }
            for (var i = 0; i < vm.selectedTenors.length; i++) {
                const thisTenorLabel = vm.selectedTenors[i];
                var curveIndex = vm.chartData.lineLegends
                    .findIndex(function (elem) {
                        return elem === thisTenorLabel + ' (' + sharpeRatioLabel + ')';
                    });
                if (curveIndex > -1) {
                    //remove line from the chart
                    vm.chartData.lineSeries.splice(curveIndex, 1);
                    //remove line from the chart legend
                    vm.chartData.lineLegends.splice(curveIndex, 1);
                    // drop confidence bands width
                    vm.chartData.lineBands.splice(curveIndex, 1);
                    // drop line width
                    vm.lineWidth.splice(curveIndex, 1);
                }
            }
        }

        function chartDataFromYcmSeries() {
            var resData = {zero: {}, irs: {}, ccy: {}, irsSr: {}, irsSrBreakeven: {}, irsSrBreakevenStd: {}};
            const curves = {
                zero: 'gov_zero_curve',
                irs: 'irs_quarterly',
                ccy: 'ccy_continuous',
                irsSr: 'sharpe_ratio_irs'
            };
            for (var crv in curves) {
                const thisCrv = crv;
                const thisCrvIx = ycmCurveSeries.findIndex(function (el) {
                    return el.name === curves[thisCrv];
                });
                if (thisCrvIx > -1 && angular.isArray(ycmCurveSeries[thisCrvIx].data)) {
                    const thisSeriesList = ycmCurveSeries[thisCrvIx].data;
                    for (var i = 0; i < thisSeriesList.length; i++) {
                        const thisSeries = thisSeriesList[i];
                        // do not add series for ignored tenors
                        if (vm.ignoreTenors.includes(thisSeries.name)) {
                            continue;
                        }
                        const tsData = thisSeries.timeseriesData.filter(function (d) {
                            return d.value !== 'NaN';
                        });
                        // sort timeseries by dates
                        if (thisSeries.name && angular.isArray(tsData) && tsData.length > 0) {
                            resData[thisCrv][thisSeries.name] = tsData
                                .sort(function (a, b) {
                                    return new Date(a.date) - new Date(b.date);
                                });
                        }
                        // in case of CCY remove 5Y tenor before 2019-04-18 when we started publishing that
                        if (thisCrv === 'ccy' && thisSeries.name && thisSeries.name === '5Y') {
                            resData[thisCrv][thisSeries.name] = resData[thisCrv][thisSeries.name]
                                .filter(function (t) {
                                    return new Date(t.date) >= new Date('2019-04-18');
                                });
                        }
                    }
                }
            }
            // irsSrBreakeven series
            for (i = 0; i < ycmBrekevenSRSeries.length; i++) {
                const thisSeries = ycmBrekevenSRSeries[i];
                // do not add series for ignored tenors
                if (vm.ignoreTenors.includes(thisSeries.name)) {
                    continue;
                }
                const tsData = thisSeries.data.filter(function (d) {
                    return d.value !== 'NaN';
                });
                // sort timeseries by dates
                if (thisSeries.name && angular.isArray(tsData) && tsData.length > 0) {
                    resData.irsSrBreakeven[thisSeries.name] = tsData
                        .sort(function (a, b) {
                            return new Date(a.date) - new Date(b.date);
                        });
                }
            }
            // irsSrBreakevenStd
            const thisCrvIx = ycmCurveSeries.findIndex(function (el) {
                return el.name === 'sharpe_ratio_irs_breakeven_std';
            });
            if (thisCrvIx > -1 && angular.isArray(ycmCurveSeries[thisCrvIx].data)) {
                const thisSeriesList = ycmCurveSeries[thisCrvIx].data;
                for (i = 0; i < thisSeriesList.length; i++) {
                    const thisSeries = thisSeriesList[i];
                    // do not add series for ignored tenors
                    if (vm.ignoreTenors.includes(thisSeries.name)) {
                        continue;
                    }
                    const tsData = thisSeries.timeseriesData.filter(function (d) {
                        return d.value !== 'NaN';
                    });
                    // sort timeseries by dates and get the last value
                    if (thisSeries.name && angular.isArray(tsData) && tsData.length > 0) {
                        const thisValue = tsData
                            .sort(function (a, b) {
                                return new Date(a.date) - new Date(b.date);
                            })[tsData.length - 1].value;
                        resData.irsSrBreakevenStd[thisSeries.name] = thisValue || 0;
                    }
                }
            }
            return resData;
        }

    }
})();
