(function () {
    'use strict';
    angular
        .module('portalApp')
        .controller('YcChartController', YcChartController);

    YcChartController.$inject = ['$state', 'subscribedCountryList', 'latestYcmRound'];

    function YcChartController($state, subscribedCountryList, latestYcmRound) {
        var vm = this;
        vm.mainView = $state.params.mainView;
        vm.chartType = $state.params.chartType || 'maturity';
        vm.country = subscribedCountryList.find(function (c) {
            return (c.id == $state.params.countryId);
        });
        vm.latestYcmRound = latestYcmRound;
        vm.switchToTable = switchToTable;
        vm.onTabSelected = onTabSelected;

        //***************************************************************


        function onTabSelected(tabName) {
            $state.go('selectedCountry', {countryId: vm.country.id, mainView: 'yc-chart', chartType: tabName});
        }

        /**
         * Switches the main panel to the actual view
         */
        function switchToTable() {
            $state.go('selectedCountry', {countryId: vm.country.id, mainView: 'actual', chartType: null});
        }

    }
})();
