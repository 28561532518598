(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['$state', 'FooterService'];

    function FooterController($state, FooterService) {
        var vm = this;

        vm.$state = $state;
        vm.applicationVersion = "";
        vm.year = new Date().getFullYear();

        FooterService.get().$promise.then(function (response) {
            vm.applicationVersion = response.applicationVersion;
        });
    }
})();
