(function () {
    'use strict';

    angular
        .module('portalApp')
        .provider('yieldCurveChartConfig', yieldCurveChartConfig);

    // configuration
    yieldCurveChartConfig.$inject = ['yieldCurveChartConstants'];
    function yieldCurveChartConfig(yieldCurveChartConstants) {
        this.yieldCurveChartDefaultObject = {
            // title: {
            //     //Title text is to be filled in the app
            //     text: '',
            //     //Configure chart title font
            //     font:{
            //         opacity: 1,
            //         fontFamily: "Open Sans",
            //         fontWeight: 'lighter',
            //         color: "#337AB6",
            //         size: '16px'
            //     }
            // },
            //Configure Primary X Axis
            primaryXAxis: {
                font: {
                    size: "12px",
                    fontFamily: "Helvetica",
                    color: "#1e2834"
                },
                visible: true, // to be changed for empty charts
                title: {visible: false, text: 'Tenor'},
                axisLine: {visible: true},
                majorGridLines: {visible: true},
                majorTickLines: {visible: true},
                rangePadding: 'none',
                valueType: 'double',
                labelPlacement: 'onticks',
                labelIntersectAction: 'multipleRows',
                isIndexed: false,
                range: {min: 0, interval: 365}
            },
            //Configure Primary Y Axis
            primaryYAxis: {
                font: {
                    size: "12px",
                    fontFamily: "Helvetica",
                    color: "#1e2834"
                },
                title: {
                    visible: true,
                    text: 'Yield (%, annually compounded)',
                    font: {
                        size: "12px",
                        fontFamily: "Helvetica",
                        color: "#1e2834"
                    }
                },
                visible: true, // to be changed for empty charts
                axisLine: {visible: true},
                opacity: 0.5,
                majorGridLines: {visible: true}//, rangePadding: 'additional'
            },
            //Configure Common Properties for all the series
            commonSeriesOptions: {
                enableAnimation: false,
                opacity: 0.75,
                border: {
                    width: 2
                },
                marker: {
                    border: {
                        width: 0
                    }
                },
                highlightSettings: {
                    enable: true,
                    mode: 'series',
                    opacity: 1
                }
            },
            //Series are to be filled in the app
            series: [],
            //Configure Zooming
            zooming: {
                enable: false,
                enableMouseWheel: false,
                enableScrollbar: true,
                type: 'x,y'
            },
            //Configure Trackball
            crosshair: {
                visible: true,
                type: 'trackball'
            },
            //OnLoad event handler
            // load: onChartLoaded,
            //Chart appearance and other properties
            chartArea: {
                border: {
                    opacity: 1
                }
            },
            border: {
                width: 0,
                color: "#5250A1"
            },
            canResize: true,
            isResponsive: true,
            legend: {
                visible: true,
                shape: 'seriesType',
                font: {
                    size: "12px",
                    fontFamily: "Helvetica",
                    color: "#1e2834"
                }
            },
            size: {
                height: '250'//,width: '300'
            },
            annotations: [{
                visible: false, // to be changed for empty charts
                content: "watermark",
                opacity: 0.2,
                region: "series"
            }],
            //customize the trackball tooltip
            // tooltip: {visible: true, format: " #point.text# : #point.y#  "},
            trackToolTip: onTooltipShown,
            axesLabelRendering: onAxisLabelRender
        };

        this.yieldCurveChartDefaultLineStyles = {
            predefined: [ //todo: only six lines are predefined so far, will see if more is needed
                {
                    width: 3,
                    color: yieldCurveChartConstants.lineColorSet[0],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: yieldCurveChartConstants.lineColorSet[1],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: yieldCurveChartConstants.lineColorSet[2],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: yieldCurveChartConstants.lineColorSet[3],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: yieldCurveChartConstants.lineColorSet[4],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: yieldCurveChartConstants.lineColorSet[5],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                },
                {
                    width: 3,
                    color: yieldCurveChartConstants.lineColorSet[6],
                    dashSetting: [1, 0],
                    markerShape: 'Circle',
                    markerSize: 2
                }
            ],
            new: {
                width: 3,
                color: null, //will be picked automatically
                dashSetting: [1, 0],
                markerShape: 'Circle',
                markerSize: 2
            }
        };

        this.xTicksTranslations = {
            "today": {en: "today", fr: "aujourd'hui"},
            "1 day": {en: "1 day", fr: "1 jour"},
            "1 week": {en: "1 week", fr: "1 semaine"},
            "2 weeks": {en: "2 weeks", fr: "2 semaines"},
            "3 weeks": {en: "3 weeks", fr: "3 semaines"},
            "1 month": {en: "1 month", fr: "1 mois"},
            "2 months": {en: "2 months", fr: "2 mois"},
            "1 quarter": {en: "1 quarter", fr: "1 trimestre"},
            "2 quarters": {en: "2 quarters", fr: "2 trimestres"},
            "3 quarters": {en: "3 quarters", fr: "3 trimestres"},
            "1 year": {en: "1 year", fr: "1 an"},
            "5 quarters": {en: "5 quarters", fr: "5 trimestres"},
            "6 quarters": {en: "6 quarters", fr: "6 trimestres"},
            "7 quarters": {en: "7 quarters", fr: "7 trimestres"},
            "2 years": {en: "2 years", fr: "2 ans"},
            "3 years": {en: "3 years", fr: "3 ans"},
            "4 years": {en: "4 years", fr: "4 ans"},
            "5 years": {en: "5 years", fr: "5 ans"},
            "6 years": {en: "6 years", fr: "6 ans"},
            "7 years": {en: "7 years", fr: "7 ans"},
            "8 years": {en: "8 years", fr: "8 ans"},
            "9 years": {en: "9 years", fr: "9 ans"},
            "10 years": {en: "10 years", fr: "10 ans"},
            "11 years": {en: "11 years", fr: "11 ans"},
            "12 years": {en: "12 years", fr: "12 ans"},
            "13 years": {en: "13 years", fr: "13 ans"},
            "14 years": {en: "14 years", fr: "14 ans"},
            "15 years": {en: "15 years", fr: "15 ans"},
            "16 years": {en: "16 years", fr: "16 ans"},
            "17 years": {en: "17 years", fr: "17 ans"},
            "18 years": {en: "18 years", fr: "18 ans"},
            "19 years": {en: "19 years", fr: "19 ans"},
            "20 years": {en: "20 years", fr: "20 ans"},
            "21 years": {en: "21 years", fr: "21 ans"},
            "22 years": {en: "22 years", fr: "22 ans"},
            "23 years": {en: "23 years", fr: "23 ans"},
            "24 years": {en: "24 years", fr: "24 ans"},
            "25 years": {en: "25 years", fr: "25 ans"},
            "26 years": {en: "26 years", fr: "26 ans"},
            "27 years": {en: "27 years", fr: "27 ans"},
            "28 years": {en: "28 years", fr: "28 ans"},
            "29 years": {en: "29 years", fr: "29 ans"},
            "30 years": {en: "30 years", fr: "30 ans"}
        };

        this.$get = function () {
            return {
                yieldCurveChartDefaults: {
                    chartObject: this.yieldCurveChartDefaultObject,
                    lineStyles: this.yieldCurveChartDefaultLineStyles,
                    xTicksTranslations: this.xTicksTranslations
                }
            };
        };

        function onTooltipShown(sender) {
            var dataPoint = sender.data.Series.dataPoint[sender.data.pointIndex];
            //reformat Y number up to 4th precision point
            sender.data.currentText = dataPoint.text + " : " + dataPoint.y.toPrecision(4);
        }

        function onAxisLabelRender(sender) {
            // format horizontal axis
            if (sender.data.axis.orientation === 'horizontal') {
                var actInterval = angular.copy(sender.data.axis.actualRange.interval);
                actInterval = (actInterval >= 365) ? 365 : 1;
                var freqLetter = (actInterval === 365) ? "Y" : "D";
                sender.data.label.Text = (sender.data.label.Text == "0")
                    ? "" // show "" for the zero tenor
                    : parseFloat(sender.data.label.Text) / actInterval + freqLetter;
            }
        }
    }
})();
