(function () {
    'use strict';
    angular
        .module('portalApp')
        .controller('ArchiveController', ArchiveController);

    ArchiveController.$inject = ['$filter', '$state', 'subscribedCountryList', 'allYcmRounds', 'archiveResources',
        'DataUtils', 'Resource'];

    function ArchiveController($filter, $state, subscribedCountryList, allYcmRounds, archiveResources,
                               DataUtils, Resource) {
        var vm = this;
        vm.downloadResource = downloadResource;
        vm.country = subscribedCountryList.find(function (c) {
            return (c.id == $state.params.countryId);
        });
        vm.allYcmRounds = allYcmRounds;
        vm.yearGroupList = [];
        // if round is not found (doesn't exist or not subscribed) => stop here
        if (angular.isArray(allYcmRounds) && allYcmRounds.length > 0) {
            // pre-process the resources (add descriptions, icon types, ordering etc.) for each round
            // and group the rounds by year
            var yearGroup = {
                uncollapsed: true,
                roundList: [],
                thisYear: allYcmRounds[0].created.slice(0, 4)
            };
            for (var rx = 0; rx < allYcmRounds.length; rx++) {
                var yStr = allYcmRounds[rx].created.slice(0, 4);
                var y = parseInt(yStr);
                if (rx > 0 && parseInt(allYcmRounds[rx - 1].created.slice(0, 4)) !== y) {
                    vm.yearGroupList.push(angular.copy(yearGroup));
                    yearGroup = {uncollapsed: false, roundList: [], thisYear: yStr};
                }
                var thisResources = archiveResources[rx].map(function (res) {
                    if (res.description.indexOf("YCM-YIELD-CURVE-SPREADSHEET") !== -1) {
                        res.ix = 0;
                        res.roundIx = rx;
                        res.desc = "YCM Report Table";
                        res.ext = "csv";
                        res.icon = "fa fa-file-excel-o fa-fw";
                    } else if (res.description.indexOf("YCM-DAILY-ZERO-CURVE-SPREADSHEET") !== -1) {
                        res.ix = 1;
                        res.roundIx = rx;
                        res.desc = "Daily Zero Curve Table";
                        res.ext = "csv";
                        res.icon = "fa fa-file-excel-o fa-fw";
                    } else if (res.description.indexOf("YCM-DAILY-CCY-CURVE-SPREADSHEET") !== -1) {
                        res.ix = 2;
                        res.roundIx = rx;
                        res.desc = "Daily CCY Rates Table";
                        res.ext = "csv";
                        res.icon = "fa fa-file-excel-o fa-fw";
                    } else if (res.description.indexOf("YCM-IRS-BREAKEVEN-SPREADSHEET") !== -1) {
                        res.ix = 3;
                        res.roundIx = rx;
                        res.desc = "Historical Break-Even Table";
                        res.ext = "csv";
                        res.icon = "fa fa-file-excel-o fa-fw";
                    } else {
                        res.ix = 4;
                        res.roundIx = rx;
                        res.desc = "Unknown file";
                        res.ext = "";
                        res.icon = "fa fa-file-o fa-fw";
                    }
                    return res;
                });
                yearGroup.roundList.push({created: allYcmRounds[rx].created, resList: angular.copy(thisResources)});
                if (rx + 1 === allYcmRounds.length) {
                    vm.yearGroupList.push(angular.copy(yearGroup));
                }
            }
        }

        /**
         * Triggers downloading -- either proper resource downloading from SVN or a data export
         * @param res -- resource object
         */
        function downloadResource(res) {
            Resource
                .download({id: res.id, download: true})
                .$promise
                .then(function (response) {
                    var fileName = vm.country.name + " -- " + res.desc + " ("
                        + $filter('date')(vm.allYcmRounds[res.roundIx].created, 'dd-MMM-yyyy')
                        + ")" + ((res.ext === "") ? "" : ("." + res.ext));
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName);
                });

        }
    }
})();
