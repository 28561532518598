(function () {
    'use strict';

    angular
        .module('portalApp')
        .directive('inputRounding', inputRounding);

    inputRounding.$inject = ['$filter', '$locale'];

    function inputRounding($filter, $locale) {
        var directive = {
            terminal: true,
            restrict: 'A',
            require: '?ngModel',
            scope: {
                decimals: '<',
                hideZero: '<'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, ngModel) {
            // number of decimals to round the number to
            var decimals = 2;
            if (angular.isDefined(scope.decimals)) {
                decimals = angular.copy(scope.decimals);
            }
            // whether to hide zeros or not
            var hideZero = false;
            if (angular.isDefined(scope.hideZero)) {
                hideZero = scope.hideZero;
            }

            if (!ngModel)
                return; // do nothing if no ng-model

            // function to do the rounding
            var roundMe = function (number) {
                if (number) {
                    return $filter('number')(number, decimals);
                }
                return number;
            };

            // Listen for change events to enable binding
            element.bind('blur', function () {
                element.val(roundMe(ngModel.$modelValue));
            });

            // push a formatter so the model knows how to render
            ngModel.$formatters.push(function (value) {
                if (value) {
                    return roundMe(value);
                }
                else if (value === 0 && !hideZero) {
                    return value;
                }
            });

            // push a parser to remove any special rendering and make sure the inputted number is rounded
            ngModel.$parsers.push(function (value) {
                return value;
            });
        }
    }
})();
