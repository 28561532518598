(function () {
    'use strict';

    angular
        .module('portalApp')
        .directive('yieldCurveChart', yieldCurveChart);

    yieldCurveChart.$inject = ['$window', 'yieldCurveChartConfig', 'DateUtils'];

    function yieldCurveChart($window, yieldCurveChartConfig, DateUtils) {
        // Generates chart for one or more timeseries
        // Usage:
        //  <div data-yield-curve-chart chart-data="vm.chartData">
        //  <div data-yield-curve-chart chart-data="vm.chartData" line-style="vm.lineStyle">
        //  <div data-yield-curve-chart chart-data="vm.chartData" min-tenor="vm.minimumTenorDays">
        //  <div data-yield-curve-chart chart-data="vm.chartData" max-tenor="vm.maximumTenorDays">
        //  <div data-yield-curve-chart chart-data="vm.chartData" curve-type="vm.curveType">
        // Input structure:
        //  vm.chartData = {
        //      chartId: "someId",
        //      title: "some title",
        //      lineSeries: {
        //          irs: [
        //              [
        //                  {tenorLabel: String, tenorDays: Int, value: Double},
        //                  ...
        //              ],
        //              ...
        //          ],
        //          ccy: [
        //              [
        //                  {tenorLabel: String, tenorDays: Int, value: Double},
        //                  ...
        //              ],
        //              ...
        //          ],
        //          zero: [
        //              [
        //                  {tenorLabel: String, tenorDays: Int, value: Double},
        //                  ...
        //              ],
        //              ...
        //          ]
        //      },
        //      lineLegends: [String, String, ... ]
        //  }
        //  vm.lineStyle = [
        //      {
        //          width: 2,
        //          color: '#3734FF',
        //          dashSetting: [<dashWidth>,<emptySpaceWidth>],
        //          markerSize: 2,
        //          markerShape: <one of the following shapes: None, LeftArrow, RightArrow, Circle, Cross, HorizLine,
        //                                                     VertLine, Diamond, Rectangle, Triangle, InvertedTriangle,
        //                                                     Hexagon, Pentagon, Star, Ellipse, Wedge, Trapezoid,
        //                                                     UpArrow, DownArrow>
        //      },
        //      ...
        //  ]

        var directive = {
            link: linkFunc,
            restrict: 'A',
            scope: {
                chartData: '<',
                lineStyle: '<',
                curveType: '<',
                minTenor: '<',
                maxTenor: '<',
                yAxisTitle: '<',
                langKey: '<'
            }
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            element.addClass('yieldCurveChart');
            element.attr('id', scope.chartData.chartId);

            makeChart();

            scope.$watch('chartData', updateChart, true);
            scope.$watch('lineStyle', updateChart, true);
            scope.$watch('curveType', updateChart, true);
            scope.$watch('minTenor', updateChart, true);
            scope.$watch('maxTenor', updateChart, true);
            scope.$watch('yAxisTitle', updateChart, true);
            scope.$watch('langKey', updateChart, true);
            //some responsiveness here: when window was resized - recalculate
            // height of the chart based on the parent height
            var win = angular.element($window);
            win.bind("resize", updateChart);
            element.on('$destroy', function () {
                win.unbind("resize", updateChart);
            });

            function updateChart() {
                //unbind this function before removing the chart
                win.unbind("resize", updateChart);
                element.ejChart("destroy");
                element.empty();
                makeChart();
                win.bind("resize", updateChart);
            }

            function makeChart() {
                // console.log('here!');
                //load defaults
                var chartObject = angular.copy(yieldCurveChartConfig.yieldCurveChartDefaults.chartObject);
                var defaultLineStyles = angular.copy(yieldCurveChartConfig.yieldCurveChartDefaults.lineStyles);
                var xTicksTranslations = angular.copy(yieldCurveChartConfig.yieldCurveChartDefaults.xTicksTranslations);
                // //get chart title from chartData
                // chartObject.title.text = angular.copy(scope.chartData.title);

                //get Y-axis title
                var yAxisTitle = angular.copy(scope.yAxisTitle);

                //get lines' legends from chartData
                var lineLegends = angular.copy(scope.chartData.lineLegends);

                //try getting lines' styles from lineStyle
                var lineStyles = angular.copy(scope.lineStyle);

                //try getting type of the curve from curveType or set it to zero-curve by default
                var curveType = angular.copy(scope.curveType);
                if (!curveType) {
                    curveType = 'zero';
                }

                //get lines' series from chartData
                var lineData;
                lineData = angular.copy(scope.chartData.lineSeries[curveType]);
                lineData.map(function (el) {
                    return el.sort(function (a, b) {
                        return parseFloat(a.tenorDays) - parseFloat(b.tenorDays);
                    });
                });

                //try getting minimum tenor from minTenor
                var minTenor = angular.copy(scope.minTenor);

                //try getting maximum tenor from maxTenor
                var maxTenor = angular.copy(scope.maxTenor);

                //try getting language key and use EN by default
                var langKey = angular.copy(scope.langKey);
                if (!langKey) {
                    langKey = 'en';
                }

                //update chart height based on the height of the parent element
                // console.log(element.parent().height());
                chartObject.size.height = (angular.copy(element.parent().height()) - 10).toString();

                //if there was an error don't try to build a chart
                if (lineData === null) {
                    element.append('<p>Unable to plot the chart</p>');
                    return;
                }

                //if there's no line styles provided fill them in with the default line styles
                var ix;
                if (lineData.length > 0 && (!lineStyles || lineStyles.length === 0)) {
                    lineStyles = [];
                    for (ix = 0; ix < Math.min(7, lineData.length); ix++) {
                        lineStyles.push(defaultLineStyles.predefined[ix]);
                    }
                }

                //if line data is empty show nice watermark
                if (lineData.length === 0) {
                    element.append('<div id="watermark" style="font-size:28px;display:none">Empty</div>');
                    chartObject.annotations[0].visible = true;
                    chartObject.primaryXAxis.visible = false;
                    chartObject.primaryYAxis.visible = false;
                    chartObject.primaryXAxis.majorGridLines.visible = false;
                    chartObject.primaryYAxis.majorGridLines.visible = false;
                    chartObject.series = [{}];
                    chartObject.primaryXAxis.stripLine = [];
                    chartObject.legend.visible = false;
                } else {
                    chartObject.annotations[0].visible = false;
                    chartObject.primaryXAxis.visible = true;
                    chartObject.primaryYAxis.visible = true;
                    chartObject.primaryXAxis.majorGridLines.visible = true;
                    chartObject.primaryYAxis.majorGridLines.visible = true;
                    chartObject.series = [];
                    chartObject.primaryXAxis.stripLine = [];
                    chartObject.legend.visible = true;
                }
                //cut the range of all chart series
                for (ix = 0; ix < lineData.length; ix++) {
                    if (angular.isDefined(lineData[ix])) {
                        if (minTenor) {
                            lineData[ix] = lineData[ix].filter(function (dataPoint) {
                                if (angular.isUndefined(dataPoint)) {
                                    return false;
                                }
                                return parseFloat(dataPoint.tenorDays) >= minTenor;
                            });
                        }
                        if (maxTenor) {
                            lineData[ix] = lineData[ix].filter(function (dataPoint) {
                                if (angular.isUndefined(dataPoint)) {
                                    return false;
                                }
                                return parseFloat(dataPoint.tenorDays) <= maxTenor;
                            });
                        }
                    }
                }
                //fill in series of chartObject with the lines' data
                var minValue = Number.MAX_VALUE;
                var maxValue = Number.MIN_VALUE;
                for (ix = 0; ix < lineData.length; ix++) {
                    if (angular.isDefined(lineData[ix])) {
                        //construct array of the series points
                        var dataPoints = [];
                        var isLeadingNan = true;
                        for (var jx = 0; jx < lineData[ix].length; jx++) {
                            if (lineData[ix][jx].tenorDays == 0) {
                                continue;
                            }
                            var xNum = parseFloat(lineData[ix][jx].tenorDays);
                            var yNum = parseFloat(lineData[ix][jx].value);
                            if (isNaN(yNum)) {
                                yNum = null;
                                if (isLeadingNan) continue;
                            } else {
                                isLeadingNan = false;
                                minValue = Math.min(minValue, yNum);
                                maxValue = Math.max(maxValue, yNum);
                            }
                            var translatedText = lineData[ix][jx].tenorLabel;
                            if (angular.isDefined(xTicksTranslations[lineData[ix][jx].tenorLabel]) && angular.isDefined(xTicksTranslations[lineData[ix][jx].tenorLabel][langKey]))
                                translatedText = xTicksTranslations[lineData[ix][jx].tenorLabel][langKey];
                            dataPoints.push({
                                x: xNum,
                                y: yNum,
                                text: translatedText
                            });
                        }
                        //use default line style if number of lines is higher the number of predefined lines in config
                        if (lineStyles.length < ix + 1) {
                            lineStyles.push(defaultLineStyles.new);
                        }
                        //use default legend if lineLegends array is shorter
                        var lineName = "";
                        if (!lineLegends || lineLegends.length < ix + 1) {
                            lineName = "curve" + (ix + 1);
                        } else {
                            lineName = lineLegends[ix];
                        }
                        //add series to the chart structure
                        chartObject.series.push({
                            fill: lineStyles[ix].color,
                            width: lineStyles[ix].width,
                            marker: {
                                visible: true,
                                shape: lineStyles[ix].markerShape,
                                size: {width: lineStyles[ix].markerSize, height: lineStyles[ix].markerSize}
                            },
                            dashArray: lineStyles[ix].dashSetting.toString(),
                            type: 'line',
                            points: dataPoints,
                            name: lineName
                        });
                    }
                }
                // change chart X-axis interval if chartMaxTenor is lower than 2Y
                if (maxTenor !== 731) {
                    chartObject.primaryXAxis.range.interval = 365;
                    if (maxTenor > 7305) {
                        chartObject.primaryXAxis.range.interval = 730;
                    }
                } else {
                    chartObject.primaryXAxis.range.interval = 90;
                }
                chartObject.primaryXAxis.range.max = maxTenor;
                // adjust Y-axis range to be tight
                if (lineData.length > 0) {
                    chartObject.primaryYAxis.range = {
                        min: Math.floor(minValue * 10) / 10 - 0.1,
                        max: Math.ceil(maxValue * 10) / 10 + 0.1
                    };
                }
                //assign Y-axis title
                chartObject.primaryYAxis.title.text = yAxisTitle;

                // console.log(chartObject);
                //generate the chart from prepared object
                element.ejChart(angular.copy(chartObject));
            }
        }
    }

})();
