(function () {
    'use strict';
    angular
        .module('portalApp')
        .controller('FpasController', FpasController);

    FpasController.$inject = ['$filter', '$state', 'subscribedCountryList', 'allFpasRounds', 'allFpasResources', 'latestFullFpasRound', 'latestFullFpasResources',
        'latestFpasRound', 'latestFpasResources', 'DataUtils', 'Resource'];

    function FpasController($filter, $state, subscribedCountryList, allFpasRounds, allFpasResources, latestFullFpasRound, latestFullFpasResources,
                            latestFpasRound, latestFpasResources, DataUtils, Resource) {
        var vm = this;
        vm.downloadResource = downloadResource;
        vm.country = subscribedCountryList.find(function (c) {
            return (c.id == $state.params.countryId);
        });
        vm.latestFpasRound = latestFpasRound;
        vm.latestFullFpasRound = latestFullFpasRound;
        vm.olderFpasRounds = allFpasRounds.filter(function (r) {
            return r.id !== vm.latestFpasRound.id;
        });
        vm.olderFpasCpiResource = [];
        for (var i = 0; i < vm.olderFpasRounds.length; i++) {
            const rnd = vm.olderFpasRounds[i];
            const resIx = allFpasResources.findIndex(function (rs) {
                return rs && rs.length && rs[0].roundId === rnd.id;
            });
            var thisCpiResource = null;
            if (resIx !== -1) {
                const cpiResIndex = allFpasResources[resIx].findIndex(function (el) {
                    return el.filename.indexOf("monthly_cpi") !== -1;
                });
                if (cpiResIndex !== -1) {
                    thisCpiResource = angular.copy(allFpasResources[resIx][cpiResIndex]);
                    thisCpiResource.desc = 'Monthly CPI Data';
                    thisCpiResource.ext = "csv";
                }
            }
            vm.olderFpasCpiResource.push(thisCpiResource);
        }
        vm.cpiArchiveOpen = false;
        // if round is not found (doesn't exist or not subscribed) => stop here
        if (latestFullFpasRound !== null && !angular.equals(latestFullFpasRound, {})) {
            // pre-process the resources (add descriptions, icon types, ordering etc.)
            vm.fpasResources = latestFullFpasResources.map(function (res) {
                if (res.filename.indexOf("presentation") !== -1) {
                    res.ix = 3;
                    res.desc = "Presentation";
                    res.ext = "pdf";
                    res.icon = "fa fa-file-pdf-o fa-fw";
                } else if (res.filename.indexOf("screening") !== -1) {
                    res.ix = 2;
                    res.desc = "Data Screening Report";
                    res.ext = "pdf";
                    res.icon = "fa fa-file-pdf-o fa-fw";
                } else if (res.filename.indexOf("short") !== -1) {
                    res.ix = 0;
                    res.desc = "Forecast Summary";
                    res.ext = "pdf";
                    res.icon = "fa fa-file-pdf-o fa-fw";
                } else {
                    res.ix = 4;
                    res.desc = "Unknown file";
                    res.ext = "";
                    res.icon = "fa fa-file-o fa-fw";
                }
                return res;
            });
        }
        const cpiResIndex = latestFpasResources.findIndex(function (el) {
            return el.filename.indexOf("monthly_cpi") !== -1;
        });
        vm.cpiResource = null;
        if (cpiResIndex !== -1) {
            vm.cpiResource = latestFpasResources[cpiResIndex];
            vm.cpiResource.desc = 'Monthly CPI Data';
            vm.cpiResource.ext = "csv";
        }

        /**
         * Triggers downloading -- either proper resource downloading from SVN or a data export
         * @param res -- resource object
         */
        function downloadResource(res, thisRound) {
            Resource
                .download({id: res.id, download: true})
                .$promise
                .then(function (response) {
                    var fileName = vm.country.name + " -- " + res.desc + " ("
                        + $filter('date')(thisRound.created, 'dd-MMM-yyyy')
                        + ")" + ((res.ext === "") ? "" : ("." + res.ext));
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName);
                });

        }
    }
})();
