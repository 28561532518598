(function () {
    'use strict';

    angular
        .module('portalApp')
        .controller('MainController', MainController);

    MainController.$inject = ['$http', '$state', '$scope', '$window', '$uibModal', '$filter', 'Principal', 'Auth',
        'subscribedCountryList', 'latestYcmRoundList', 'latestYcmResourcesList', 'Resource', 'DataUtils',
        'SummaryFile'];

    function MainController($http, $state, $scope, $window, $uibModal, $filter, Principal, Auth,
        subscribedCountryList, latestYcmRoundList, latestYcmResourcesList, Resource, DataUtils,
        SummaryFile) {

        var vm = this;
        vm.$state = $state;
        //exclude external sector from the list of countries for the panel
        vm.subscribedCountryList = subscribedCountryList.filter(function (country) {
            return (country.id !== 73);
        });
        vm.latestYcmRoundList = latestYcmRoundList;
        vm.latestYcmResourcesList = latestYcmResourcesList.map(function (res) {
            var resNew = {roundId: -1, resList: []};
            if (res.length > 0) {
                resNew.roundId = angular.copy(res[0].roundId);
                resNew.resList = angular.copy(res);
            }
            return resNew;
        });

        vm.onCountrySelected = onCountrySelected;
        vm.showAcceptProtectionPolicy = showAcceptProtectionPolicy;
        vm.resourcesForRound = resourcesForRound;
        vm.downloadResource = downloadResource;
        vm.downloadSummary = downloadSummary;
        vm.verifyProtectionPolicyAcceptance = verifyProtectionPolicyAcceptance;

        vm.verifyProtectionPolicyAcceptance();

        function onCountrySelected(countryId) {
            // when switching between countries show the same "subpage" (mainView + chartType)
            // but when leaving from 'overview' always show the 'actual' mainView
            if (!$state.includes('selectedCountry')) {
                $state.go('selectedCountry', {
                    countryId: countryId,
                    mainView: 'actual',
                    chartType: null
                });
            } else {
                $state.go('selectedCountry', {
                    countryId: countryId,
                    mainView: $state.params.mainView,
                    chartType: $state.params.chartType
                });
            }
        }

        function resourcesForRound(id) {
            if (angular.isUndefined(id)) {
                return null;
            }
            var ix = vm.latestYcmResourcesList.findIndex(function (el) {
                return el.roundId === id;
            });
            if (ix === -1) {
                return null;
            }
            return vm.latestYcmResourcesList[ix].resList.map(function (res) {
                if (res.description.indexOf("YCM-YIELD-CURVE-SPREADSHEET") !== -1) {
                    res.ix = 0;
                    res.desc = "YCM Report Table";
                    res.ext = "csv";
                    res.icon = "fa fa-file-excel-o fa-fw";
                } else if (res.description.indexOf("YCM-DAILY-ZERO-CURVE-SPREADSHEET") !== -1) {
                    res.ix = 1;
                    res.desc = "Daily Zero Curve Table";
                    res.ext = "csv";
                    res.icon = "fa fa-file-excel-o fa-fw";
                } else if (res.description.indexOf("YCM-DAILY-CCY-CURVE-SPREADSHEET") !== -1) {
                    res.ix = 2;
                    res.desc = "Daily CCY Rates Table";
                    res.ext = "csv";
                    res.icon = "fa fa-file-excel-o fa-fw";
                } else if (res.description.indexOf("YCM-IRS-BREAKEVEN-SPREADSHEET") !== -1) {
                    res.ix = 3;
                    res.desc = "Historical Break-Even Table";
                    res.ext = "csv";
                    res.icon = "fa fa-file-excel-o fa-fw";
                } else {
                    res.ix = 4;
                    res.desc = "Unknown file";
                    res.ext = "";
                    res.icon = "fa fa-file-o fa-fw";
                }
                return res;
            });
        }

        /**
         * Triggers downloading -- either proper resource downloading from SVN or a data export
         * @param res   -- resource object
         * @param round -- round object
         */
        function downloadResource(res, round) {
            Resource
                .download({id: res.id, download: true})
                .$promise
                .then(function (response) {
                    var fileName = round.countryName + " -- " + res.desc + " ("
                        + $filter('date')(round.created, 'dd-MMM-yyyy')
                        + ")" + ((res.ext === "") ? "" : ("." + res.ext));
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName);
                });

        }

        function downloadSummary() {
            SummaryFile
                .download()
                .$promise
                .then(function (response) {
                    var fileName = "YCM-OGResearch-Bloomberg"
                        //+ $filter('date')(new Date(), 'dd-MMM-yyyy')
                        +".csv";
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName);
                });

        }

        function verifyProtectionPolicyAcceptance() {
            $http({
                method: 'GET',
                url: '/api/user_acceptance?code=GDPR-text'
            }).then(function (response) {
                // this is ok
            }, function (response) {
                vm.showAcceptProtectionPolicy();
            });
        }

        function showAcceptProtectionPolicy() {
            $uibModal.open({
                templateUrl: 'app/admin/protection-policy/protection-policy.modal.html',
                controller: 'ProtectionPolicyModalController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false
            });
        }
    }
})();
