(function () {
    'use strict';
    angular
        .module('portalApp')
        .factory('YcmCalculation', YcmCalculation);

    YcmCalculation.$inject = ['$resource'];

    function YcmCalculation($resource) {
        var resourceUrl = 'api/ycm-calculation/';

        return function (curveType, isAsync) {
            if (angular.isUndefined(isAsync)) {
                isAsync = false;
            }
            if (isAsync) {
                return 0;
            }
            return $resource(resourceUrl + curveType + '/', {}, {
                'calculate': {method: 'POST', isArray: true}
            });
        };
    }

})();
