(function () {
    'use strict';
    angular
        .module('portalApp')
        .controller('ActualController', ActualController);

    ActualController.$inject = ['$filter', '$q', '$sce', '$state', 'subscribedCountryList', 'latestYcmRound', 'latestYcmResults',
        'latestYcmResources', 'DataUtils', 'Resource', 'YcmCalculation', 'ycTableConstants'];

    function ActualController($filter, $q, $sce, $state, subscribedCountryList, latestYcmRound, latestYcmResults,
        latestYcmResources, DataUtils, Resource, YcmCalculation, ycTableConstants) {
        var vm = this;
        vm.mainView = $state.params.mainView;
        vm.country = subscribedCountryList.find(function (c) {
            return (c.id == $state.params.countryId);
        });
        vm.noRecalculation = checkIfNoRecalculate();
        vm.tableRowOrder = ['zero_curve', 'gov_zero_curve', 'irs_quarterly', 'sharpe_ratio_irs', 'sharpe_ratio_zc',
            'ccy_semiannual', 'ccy_continuous', 'sharpe_ratio_ccy', 'fpas_compounded_mean', 'fpas_compounded_std'];
        vm.editableRowsList = (vm.noRecalculation) ? [] : ['sharpe_ratio_zc'];
        vm.lastRowsOfBlocks = ['sharpe_ratio_zc', 'sharpe_ratio_ccy'];
        vm.latestYcmRound = latestYcmRound;
        vm.ycmResources = [];
        vm.tableData = {};
        vm.origTableData = {};
        vm.tableTenorState = ycTableConstants.tenorList.labels.reduce(function (accum, tLab) {
            accum[tLab] = {
                recalculated: true,
                calculating: false,
                failed: false
            };
            return accum;
        }, {});
        vm.downloadResource = downloadResource;
        vm.dataTableFromYcmResults = dataTableFromYcmResults;
        vm.fillTableColumnFromYcData = fillTableColumnFromYcData;
        vm.fillTableRowFromYcData = fillTableRowFromYcData;
        vm.switchToChart = switchToChart;
        vm.recalculate = (vm.noRecalculation) ? null : recalculate;

        // if round is not found (doesn't exist or not subscribed) => stop here
        if (latestYcmRound !== null && !angular.equals(latestYcmRound, {})) {
            // create table data for YC directive out of YCM results
            if (angular.isArray(latestYcmResults) && latestYcmResults.length > 0) {
                vm.tableData = dataTableFromYcmResults(latestYcmResults);
                vm.origTableData = angular.copy(vm.tableData);
            }
            // pre-process the resources (add descriptions, icon types, ordering etc.)
            vm.ycmResources = latestYcmResources.map(function (res) {
                if (res.description.indexOf("YCM-YIELD-CURVE-SPREADSHEET") !== -1) {
                    res.ix = 0;
                    res.desc = "YCM Report Table";
                    res.ext = "csv";
                    res.icon = "fa fa-file-excel-o fa-fw";
                } else if (res.description.indexOf("YCM-DAILY-ZERO-CURVE-SPREADSHEET") !== -1) {
                    res.ix = 1;
                    res.desc = "Daily Zero Curve Table";
                    res.ext = "csv";
                    res.icon = "fa fa-file-excel-o fa-fw";
                } else if (res.description.indexOf("YCM-DAILY-CCY-CURVE-SPREADSHEET") !== -1) {
                    res.ix = 2;
                    res.desc = "Daily CCY Rates Table";
                    res.ext = "csv";
                    res.icon = "fa fa-file-excel-o fa-fw";
                } else {
                    res.ix = 3;
                    res.desc = "Unknown file";
                    res.ext = "";
                    res.icon = "fa fa-file-o fa-fw";
                }
                return res;
            });
        }

        /**
         * Recalculates the yields and Sharpe ratios for the specified tenor(s) and value(s) of zero-coupon SR
         *
         * @param tnr -- array of modified tenors
         * @param val -- array of new zero-coupon SR values
         */
        function recalculate(tnr, val) {
            const zeroCurveIx = latestYcmResults.findIndex(function (res) {
                return res.name === 'zero_curve';
            });
            const zeroCurveSrIx = latestYcmResults.findIndex(function (res) {
                return res.name === 'sharpe_ratio_zc';
            });
            const irsCurveIx = latestYcmResults.findIndex(function (res) {
                return res.name === 'irs_quarterly';
            });
            const irsCurveSrIx = latestYcmResults.findIndex(function (res) {
                return res.name === 'sharpe_ratio_irs';
            });
            if (zeroCurveSrIx > -1 && zeroCurveIx > -1) {
                // first, we recalculate ZERO and GOV ZERO curves for all modified tenors,
                // as recalculated zero curve is going to be used as discount factors for the following
                // IRS recalculation
                const zeroCurveSrData = latestYcmResults[zeroCurveSrIx].yieldCurveData;
                var zcPromises = [];
                for (var i = 0; i < tnr.length; i++) {
                    const thisValue = val[i];
                    const thisTenor = tnr[i];
                    const zeroCurveSrTenorIx = zeroCurveSrData.findIndex(function (tnr) {
                        return tnr.tenorLabel === thisTenor;
                    });
                    if (zeroCurveSrTenorIx > -1) {
                        vm.tableTenorState[thisTenor].calculating = true;
                        vm.tableTenorState[thisTenor].failed = false;
                        var thisZeroCurveSrTenorData = angular.copy(zeroCurveSrData[zeroCurveSrTenorIx]);
                        thisZeroCurveSrTenorData.value = thisValue;
                        var thisPromise = YcmCalculation('zero')
                            .calculate(thisZeroCurveSrTenorData)
                            .$promise
                            .then(function (res) {
                                fillTableColumnFromYcData(res, thisTenor);
                            })
                            .catch(function () {
                                vm.tableTenorState[thisTenor].calculating = false;
                                vm.tableTenorState[thisTenor].recalculated = false;
                                vm.tableTenorState[thisTenor].failed = true;
                            });
                        zcPromises.push(thisPromise);
                    }
                }

                // second we recalculate IRS coupons and Sharpe ratios
                if (irsCurveIx > -1 && irsCurveSrIx > -1) {
                    $q.all(zcPromises)
                        .then(function () {
                            const modifiedZeroCurveSr = updateCurveDataFromTable(latestYcmResults[zeroCurveSrIx]);
                            const modifiedZeroCurve = updateCurveDataFromTable(latestYcmResults[zeroCurveIx]);
                            var thisPromise = callYcmCalculation(tnr, 'irs', [modifiedZeroCurveSr, modifiedZeroCurve], false);
                            // third we recalculate CCY rates and Sharpe ratios
                            $q.when(thisPromise)
                                .then(function () {
                                    const modifiedIrsCurveSr = updateCurveDataFromTable(latestYcmResults[irsCurveSrIx]);
                                    const modifiedIrsCurve = updateCurveDataFromTable(latestYcmResults[irsCurveIx]);
                                    callYcmCalculation(tnr, 'ccy', [modifiedIrsCurveSr, modifiedIrsCurve], true);
                                });
                        });
                }
            }
        }

        /**
         * Sends the calculation request of the specified type with the provided request parameters
         * and fills the table with the resulting curves
         *
         * @param tnr               -- array of modified tenors
         * @param calculationType   -- type of calculation: 'irs' or 'ccy'
         * @param requestParameters -- array of YieldCurveDTO objects used as an input for the
         *                             corresponding calculation
         * @param isLastInQueue     -- whether this is the last call to calculation after which
         *                             the calculation indicator should be updated
         */
        function callYcmCalculation(tnr, calculationType, requestParameters, isLastInQueue) {
            return YcmCalculation(calculationType).calculate(requestParameters)
                .$promise
                .then(function (res) {
                    for (var i = 0; i < res.length; i++) {
                        fillTableRowFromYcData(res[i].yieldCurveData, res[i].name);
                    }
                    if (isLastInQueue) {
                        for (i = 0; i < tnr.length; i++) {
                            const thisTenor = tnr[i];
                            vm.tableTenorState[thisTenor].calculating = false;
                            vm.tableTenorState[thisTenor].recalculated = true;
                            vm.tableTenorState[thisTenor].failed = false;
                        }
                    }
                })
                .catch(function () {
                    for (var i = 0; i < tnr.length; i++) {
                        const thisTenor = tnr[i];
                        vm.tableTenorState[thisTenor].calculating = false;
                        vm.tableTenorState[thisTenor].recalculated = false;
                        vm.tableTenorState[thisTenor].failed = true;
                    }
                });
        }

        /**
         * Switches the main panel to the yc-chart view ("by maturity" tab)
         *
         */
        function switchToChart() {
            $state.go('selectedCountry', {countryId: vm.country.id, mainView: 'yc-chart', chartType: 'maturity'});
        }

        /**
         * Converts YCM results to the input structure for yc-table directive
         *
         * @param ycmResults -- resulting array of curves
         * @returns {{rowHeaders: Array, tableId: string, hideTenors: number[], rows: Array, rowTooltips: Array, rowUnits: Array}}
         */
        function dataTableFromYcmResults(ycmResults) {
            var tblData = {
                tableId: 'actual-tbl',
                hideTenors: [1, 7, Math.floor(365 / 12), Math.floor(365 / 6)],
                rowIds: [],
                rowHeaders: [],
                rowTooltips: [],
                rowUnits: [],
                rows: []
            };
            for (var rx = 0; rx < vm.tableRowOrder.length; rx++) {
                const thisRx = rx;
                const thisIx = ycmResults.findIndex(function (res) {
                    return res.name === vm.tableRowOrder[thisRx];
                });
                if (thisIx !== -1) {
                    const thisCurve = angular.copy(ycmResults[thisIx]);
                    const isEditable = vm.editableRowsList.includes(vm.tableRowOrder[thisRx]);
                    const isLastInBlock = vm.lastRowsOfBlocks.includes(vm.tableRowOrder[thisRx]);
                    tblData.rowIds.push(vm.tableRowOrder[thisRx]);
                    tblData.rowHeaders.push(thisCurve.label);
                    tblData.rowTooltips.push(thisCurve.description);
                    tblData.rowUnits.push($sce.trustAsHtml(thisCurve.unit));
                    var rowData = {};
                    for (var dx = 0; dx < thisCurve.yieldCurveData.length; dx++) {
                        const col = thisCurve.yieldCurveData[dx];
                        if (col.value === 'NaN') {
                            col.value = '';
                        }
                        rowData[col.tenorLabel] = {
                            value: col.value,
                            isEditable: isEditable,
                            isLastInBlock: isLastInBlock
                        };
                    }
                    tblData.rows.push(rowData);
                }
            }
            return tblData;
        }

        /**
         * Fills in the column of tableData with the values from the list of YieldCurveDataDTO
         *
         * @param tenorLabel         -- tenor label for which to fill in the tableData
         * @param yieldCurveDataList -- (re)calculated YCM results in a form of
         *                              List<YieldCurveDataDTO>
         */
        function fillTableColumnFromYcData(yieldCurveDataList, tenorLabel) {
            for (var rx = 0; rx < vm.tableRowOrder.length; rx++) {
                const thisRx = rx;
                const thisIx = yieldCurveDataList.findIndex(function (res) {
                    return res.yieldCurveName === vm.tableRowOrder[thisRx];
                });
                const thisVmTableRowIx = vm.tableData.rowIds.findIndex(function (res) {
                    return res === vm.tableRowOrder[thisRx];
                });
                if (thisIx > -1 && yieldCurveDataList[thisIx].tenorLabel === tenorLabel) {
                    vm.tableData.rows[thisVmTableRowIx][tenorLabel].value =
                        (yieldCurveDataList[thisIx].value === 'NaN')
                            ? '' : yieldCurveDataList[thisIx].value;
                }
            }
        }

        /**
         * Fills in the row of tableData with the values from the list of YieldCurveDataDTO
         *
         * @param curveName          -- name of the curve for which to fill in the tableData
         * @param yieldCurveDataList -- (re)calculated YCM results in a form of
         *                              List<YieldCurveDataDTO>
         */
        function fillTableRowFromYcData(yieldCurveDataList, curveName) {
            const thisVmTableRowIx = vm.tableData.rowIds.findIndex(function (res) {
                return res === curveName;
            });
            for (var i = 0; i < yieldCurveDataList.length; i++) {
                const thisTenorData = yieldCurveDataList[i];
                if (thisVmTableRowIx > -1 && thisTenorData.yieldCurveName === curveName) {
                    vm.tableData.rows[thisVmTableRowIx][thisTenorData.tenorLabel].value =
                        (thisTenorData.value === 'NaN')
                            ? '' : thisTenorData.value;
                }
            }
        }

        /**
         * Triggers server-side downloading from SVN (published_results, usually)
         *
         * @param res -- resource object
         */
        function downloadResource(res) {
            Resource
                .download({id: res.id, download: true})
                .$promise
                .then(function (response) {
                    var fileName = vm.country.name + " -- " + res.desc + " ("
                        + $filter('date')(vm.latestYcmRound.created, 'dd-MMM-yyyy')
                        + ")" + ((res.ext === "") ? "" : ("." + res.ext));
                    DataUtils.saveFile(response.headers["content-type"],
                        response.data, fileName);
                });

        }

        /**
         * Uses the values from the corresponding table row and populates the yieldCurveData array
         * of the input YieldCurveDTO object
         *
         * @param ycmResults -- original YieldCurveDTO object
         * @returns {*}
         */
        function updateCurveDataFromTable(ycmResults) {
            const thisCurveIx = vm.tableData.rowIds.findIndex(function (r) {
                return r === ycmResults.name;
            });
            var outYcmResults = angular.copy(ycmResults);
            if (thisCurveIx > -1 && angular.isDefined(ycmResults.yieldCurveData) && angular.isArray(ycmResults.yieldCurveData)) {
                const thisCurve = vm.tableData.rows[thisCurveIx];
                for (var i = 0; i < ycmResults.yieldCurveData.length; i++) {
                    var val = thisCurve[outYcmResults.yieldCurveData[i].tenorLabel].value;
                    outYcmResults.yieldCurveData[i].value = (val === '') ? 'NaN' : val;

                }
            }
            return outYcmResults;
        }

        function checkIfNoRecalculate() {
            const zeroCurveSrIx = latestYcmResults.findIndex(function (res) {
                return res.name === 'sharpe_ratio_zc';
            });
            if (zeroCurveSrIx === -1) {
                return true;
            }
            const zeroCurveSrData = latestYcmResults[zeroCurveSrIx].yieldCurveData;
            if (!zeroCurveSrData) {
                return true;
            }
            // check if all values are NaN
            for (var i = 0; i < zeroCurveSrData.length; i++) {
                if (zeroCurveSrData[i].value !== 'NaN'
                    && zeroCurveSrData[i].value !== ''
                    && zeroCurveSrData[i].value !== null
                    && zeroCurveSrData[i].value !== undefined) {
                    return false;
                }
            }
            return true;
        }
    }
})();
